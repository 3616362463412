/* eslint-disable @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'
import { convertStringToPx, toRem } from '../../../../helpers/theme'
import type { ParsedInscreenData } from './Inscreen.helpers'
import { PositionX, PositionY } from './Inscreen.interface'

export type StyleProps = {
    theme?: any
    dimensions: ParsedInscreenData['dimensions']
}

const parsePositionBooleans = (positionX: PositionX, positionY: PositionY, hasCenter = true) => ({
    isCenterX: hasCenter && positionX === PositionX.CENTER,
    isCenterY: hasCenter && positionY === PositionY.CENTER,
    isTop: positionY === PositionY.TOP,
    isBottom: positionY === PositionY.BOTTOM || (!hasCenter && !(positionY === PositionY.TOP)),
    isLeft: positionX === PositionX.LEFT,
    isRight: positionX === PositionX.RIGHT || (!hasCenter && !(positionX === PositionX.LEFT))
})

const styles = ({ theme, dimensions }: StyleProps) => {
    const {
        positionX = PositionX.CENTER,
        positionY = PositionY.BOTTOM,
        height: creativeHeight,
        width: creativeWidth
    } = dimensions || {}

    const { isCenterX, isCenterY, isTop, isBottom, isLeft, isRight } = parsePositionBooleans(positionX, positionY)

    // @TODO: refactor this to theme.zIndex.inscreen global
    const zIndex = 10001
    const contentWidth = convertStringToPx(creativeWidth) || 'content-width'
    const contentHeight = convertStringToPx(creativeHeight) || 'content-height'
    const containerWidth = (isCenterX && '100%') || contentWidth

    const globalHeaderHeightTheme = theme.global?.header?.height

    const headerHeightXs = globalHeaderHeightTheme?.xs?.open ?? globalHeaderHeightTheme?.xs ?? 30
    const headerHeightMd = globalHeaderHeightTheme?.md?.open ?? globalHeaderHeightTheme?.md ?? 50

    const containerHeightXs = (isCenterY && `calc(100% - ${toRem(headerHeightXs)})`) || contentHeight
    const containerHeightMd = (isCenterY && `calc(100% - ${toRem(headerHeightMd)})`) || contentHeight
    return {
        position: 'fixed',
        zIndex,
        '.Inscreen': {
            '&_container': {
                position: 'fixed',
                top: isCenterY || isTop ? headerHeightXs : 'auto',
                bottom: isBottom ? 0 : 'auto',
                left: isCenterX || isLeft ? 0 : 'auto',
                right: isRight ? 0 : 'auto',
                width: containerWidth,
                height: containerHeightXs,
                overflow: 'visible',
                pointerEvents: 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: isCenterY ? 'center' : isTop ? 'flex-start' : 'flex-end',
                justifyContent: isCenterX ? 'center' : isLeft ? 'flex-start' : 'flex-end',
                [theme.breakpoints.up('md')]: {
                    height: containerHeightMd,
                    top: isCenterY || isTop ? headerHeightMd : 'auto'
                }
            },
            '&_trackers': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                minWidth: 20,
                minHeight: 20
            },
            '&_content': {
                zIndex,
                position: 'relative',
                overflow: 'visible',
                pointerEvents: 'all',
                width: contentWidth,
                height: contentHeight,

                '& iframe': {
                    backgroundColor: isCenterX && isCenterY ? '#6722ff' : 'transparent'
                }
            }
        }
    }
}
const Styled = createStyledComponent({
    tag: 'div',
    styles,
    blockProps: ['height', 'width', 'dimensions']
})
export default Styled

type CloseButtonProps = {
    theme?: any
    positionY?: PositionY
    positionX?: PositionX
    size?: number
}

export const StyledCloseButton = createStyledComponent({
    styles: ({ positionY = PositionY.TOP, positionX = PositionX.RIGHT, size = 30 }: CloseButtonProps) => {
        const parsedPosition = parsePositionBooleans(positionX, positionY, false)
        const offset = -size / 2
        return {
            zIndex: 10002,
            position: 'absolute',
            pointerEvents: 'all',
            width: size + 'px',
            height: size + 'px',
            top: parsedPosition.isTop ? offset : 'auto',
            bottom: parsedPosition.isBottom ? offset : 'auto',
            left: parsedPosition.isLeft ? offset : 'auto',
            right: parsedPosition.isRight ? offset : 'auto'
        }
    },
    tag: 'button',
    forwardProps: ['onClick']
})
