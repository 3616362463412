/* eslint-disable @typescript-eslint/naming-convention */
import { useAmp } from 'next/amp'
import React, { useCallback } from 'react'

import { useNativeAdsState } from '../../../../selectors/ads'
import Icon from '../../../Icon/Icon.component'
import CloseCircle from '../../../Icon/icons/CloseCircle'
import { InjectHtml } from '../components/InjectHtml'
import { Invisible } from '../components/Invisible'
import { TrackingPixel } from '../components/TrackingPixel'
import {
    ParsedInscreenData // useInscreenMockData
} from './Inscreen.helpers'
import { CLEAR_INSCREEN_CALLBACK_KEY, INJECTED_INSCREEN_SCRIPTS_VARIABLE_NAME } from './Inscreen.provider'
import InscreenStyled, { StyledCloseButton } from './Inscreen.style'
import InscreenCreative from './InscreenCreative.component'

const InscreenCloseButton = ({ positionX, positionY, onClick, size = 30 }) => {
    return (
        <StyledCloseButton positionX={positionX} positionY={positionY} size={size} onClick={onClick}>
            {/* @ts-expect-error: js component prop types are not being detected properly */}
            <Icon icon={CloseCircle} size={size * 1.3} svg={undefined} color={undefined} rotate={undefined} />
        </StyledCloseButton>
    )
}

const Inscreen = () => {
    const isAmp = useAmp()
    const inscreenData = useNativeAdsState('inscreen') as ParsedInscreenData
    // const inscreenMockData = useInscreenMockData()
    const { creative, ad, dimensions, clickOptions } = inscreenData || {}

    const onCloseCallback = useCallback(() => {
        if (typeof window === 'undefined') return
        window?.[CLEAR_INSCREEN_CALLBACK_KEY]?.()
    }, [])
    if (isAmp || !ad) return null
    return (
        <>
            <InscreenStyled dimensions={dimensions}>
                <div className="Inscreen_container">
                    <div className="Inscreen_content">
                        <div className="Inscreen_trackers">
                            {/* Impression tracking via html*/}
                            {clickOptions?.thirdPartyTagsHtml && (
                                <InjectHtml
                                    windowKey={INJECTED_INSCREEN_SCRIPTS_VARIABLE_NAME}
                                    html={clickOptions?.thirdPartyTagsHtml}
                                />
                            )}
                            <TrackingPixel src={clickOptions?.viewUrlUnesc} />
                            <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} />
                        </div>

                        <InscreenCreative
                            ad={ad}
                            creative={creative}
                            clickOptions={clickOptions}
                            dimensions={dimensions}
                        />

                        {!dimensions.closeHidden && (
                            <InscreenCloseButton
                                positionY={dimensions.closePositionY}
                                positionX={dimensions.closePositionX}
                                onClick={onCloseCallback}
                            />
                        )}
                    </div>
                </div>
            </InscreenStyled>
            {/* <Invisible> */}
            {/* Impression tracking via pixels */}
            {/* <TrackingPixel src={clickOptions?.viewUrlUnesc} /> */}
            {/* <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} /> */}
            {/* </Invisible> */}
        </>
    )
}

export default Inscreen
