// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react'

import { useAdElementHref } from '../hooks/useAdElementHref'
import { ParsedConvergerData } from './ConvergerNew.helpers'

const ConvergerCreative = ({
    ad,
    clickOptions,
    numOfTiles
}: {
    ad: NonNullable<ParsedConvergerData['ad']>
    clickOptions: NonNullable<ParsedConvergerData['clickOptions']>
    numOfTiles: number
}) => {
    const href = useAdElementHref(ad, clickOptions)
    const { openInNewTab } = clickOptions

    return (
        <a
            id="converger_creative"
            className="container is_active"
            href={href}
            target={openInNewTab ? '_blank' : '_self'}>
            <div className="tiles is_left">
                {Array.from({ length: numOfTiles }, (v, i) => (
                    <div key={`is_left_${i}`} className="tile" />
                ))}
            </div>

            <div className="tiles is_right">
                {Array.from({ length: numOfTiles }, (v, i) => (
                    <div key={`is_right_${i}`} className="tile" />
                ))}
            </div>
        </a>
    )
}
export default ConvergerCreative
