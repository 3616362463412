/* eslint-disable @typescript-eslint/naming-convention */

import { keyframes } from '@emotion/react'

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'
import type { ParsedConvergerData } from './ConvergerNew.helpers'
import { PositionX, PositionY } from './ConvergerNew.interface'

export type StyleProps = {
    theme?: any
    creative?: ParsedConvergerData['creative']
    dimensions?: ParsedConvergerData['dimensions']
    numOfTiles: number
}

const slideIn = keyframes({
    '0%': { transform: 'translateX(-100%)', opacity: 0 },
    '100%': { transform: 'translateX(0)', opacity: 1 }
})

const slideInRight = keyframes({
    '0%': { transform: 'translateX(100%)', opacity: 0 },
    '100%': { transform: 'translateX(0)', opacity: 1 }
})

const scaleIn = keyframes({
    '0%': { scale: '0 1', opacity: 0 },
    '99%': { scale: '1.01 1', opacity: 1 },
    '100%': { scale: '1 1', opacity: 1 }
})

// dynamically generate nth-child styles
const tileNthChildStyles = (tilesNum: number, delay: number): Record<string, any> => {
    return Array.from({ length: tilesNum }).reduce<Record<string, any>>((acc, _, i) => {
        const index = i + 1
        acc[`&:nth-of-type(${index})`] = {
            backgroundPosition: `50% calc(${-i * (100 / tilesNum)}vh + var(--conv-offset, 0%))`,
            animationDelay: `${delay + 0.1 * index}s !important`
        }
        return acc
    }, {})
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = ({ theme, creative, numOfTiles, dimensions, delay = 3 }) => {
    const { fileImage, fileMobileImage, imageBackgroundColor, effect } = creative

    return {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10003,
        width: '100%',
        height: '100lvh',

        '.container': {
            pointerEvents: 'all',
            display: 'block',
            width: '100%',
            height: '100lvh'
        },

        '.tiles': {
            position: 'absolute',
            width: '100%',
            height: '100lvh',
            overflow: 'hidden',

            '&.is_right': {
                display: 'none'
            },

            '@media (min-width: 768px)': {
                width: '50%',
                top: 0,

                '&.is_left': {
                    left: 0
                },

                '&.is_right': {
                    right: 0,
                    display: 'block',

                    '> .tile': {
                        marginLeft: '-100%',
                        animation: `${effect === 'Scale' ? scaleIn : slideInRight} 1s ease forwards`,
                        // animationTimingFunction: 'cubic-bezier(0.1, -0.6, 0.2, 0)'
                        backfaceVisibility: 'hidden',
                        willChange: 'transform'
                    }
                }
            }
        },

        '.tile': {
            width: '100%',
            height: `${100 / numOfTiles}%`,
            background: `url(${fileMobileImage || fileImage}) no-repeat center center`,
            backgroundColor: `${imageBackgroundColor}`,
            backgroundSize: fileMobileImage ? 'min(140%, 70vh)' : 'min(240%, 140vh)',
            // pointerEvents: 'none', // disable pointer events for now

            opacity: 0,
            animation: `${effect === 'Scale' ? scaleIn : slideIn} 1s ease forwards`,
            // animationTimingFunction: 'cubic-bezier(0.1, -0.6, 0.2, 0)',
            backfaceVisibility: 'hidden',
            willChange: 'transform',

            ...tileNthChildStyles(numOfTiles, delay),

            [theme.breakpoints.up('md')]: {
                width: '200%',
                height: `${100 / numOfTiles}%`,
                backgroundImage: `url(${fileImage})`,
                // backgroundSize: 'var(--conv-size, 100%)'
                backgroundSize: 'min(140%, 150vh)'
            }
        }
    }
}

const Styled = createStyledComponent({
    tag: 'div',
    styles,
    blockProps: ['height', 'width', 'dimensions', 'numOfTiles', 'delay']
})
export default Styled

type CloseButtonProps = {
    theme?: any
    positionY?: PositionY
    positionX?: PositionX
    size?: number
    countdown?: number
    delay?: number
    disabled?: boolean
}

// button styles and animation
const strokeDashArray = '80px'
const buttonCountdown = keyframes({
    '0%': { opacity: 0 },
    '1%': { opacity: 1, strokeDashoffset: strokeDashArray },
    '100%': { strokeDashoffset: '0px' }
})

export const StyledCloseButton = createStyledComponent({
    styles: ({ size = 30, countdown = 3, delay = 3, disabled = true }: CloseButtonProps) => {
        const offset = size / 2

        return {
            pointerEvents: 'all',

            position: 'absolute',
            top: offset,
            right: offset,
            zIndex: 10004,

            width: size + 'px',
            height: size + 'px',

            '& svg': {
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.5 : 1,
                transform: 'rotateZ(-90deg)',

                text: {
                    fill: 'white',
                    stroke: 'none',
                    transform: 'rotateZ(90deg)',
                    fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif'
                },

                circle: {
                    strokeLinecap: 'round',
                    strokeWidth: '2px',
                    stroke: 'white',
                    fill: 'none',

                    strokeDasharray: strokeDashArray,
                    strokeDashoffset: strokeDashArray,
                    animation: `${buttonCountdown} ${countdown}s linear forwards`,
                    animationDelay: `${delay}s`
                }
            }
        }
    },
    tag: 'button',
    forwardProps: ['onClick']
})
