/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'

import { useAdManifest } from '../../../context/ads/manifest.context'
import { ProcessedAdList } from '../../../types/ads/Ad.interface'
import Converger from './Converger/Converger.component'
import ConvergerNew from './ConvergerNew/ConvergerNew.component'
import Inscreen from './Inscreen/Inscreen.component'
import Wallpaper from './Wallpaper/Wallpaper.component'

export type ExternalAdsProps = {
    wallpaperProps: Parameters<typeof Wallpaper>[0]
}

const ExternalAds = ({ wallpaperProps }) => {
    const { enabled: adsEnabled = false } = (useAdManifest() as ProcessedAdList) || {}
    return (
        <>
            <Wallpaper {...wallpaperProps} />
            {adsEnabled && <Converger />}
            {adsEnabled && <ConvergerNew />}
            {adsEnabled && <Inscreen />}
        </>
    )
}

export default ExternalAds
