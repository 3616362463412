/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react'

import { useNativeAdsState } from '../../../../selectors/ads'
import { InjectHtml } from '../components/InjectHtml'
import { Invisible } from '../components/Invisible'
import { TrackingPixel } from '../components/TrackingPixel'
import { ParsedConvergerData /* , useConvergerMockData*/ } from './ConvergerNew.helpers'
import { CLEAR_CONVERGER_CALLBACK_KEY, INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME } from './ConvergerNew.provider'
import ConvergerStyled, { StyledCloseButton } from './ConvergerNew.style'
import ConvergerCreative from './ConvergerNewCreative.component'

const ConvergerNew = () => {
    const convergerData = useNativeAdsState('convergerNew') as ParsedConvergerData
    // const convergerMockData = useConvergerMockData()
    const { ad, creative, dimensions, clickOptions } = convergerData || {}
    // const { ad, creative, dimensions, clickOptions } = convergerMockData || {}
    const numOfTiles = 20
    const delay = 0.3
    const countdown = 3

    const [count, setCount] = useState(3)
    const [isDisabled, setIsDisabled] = useState(true)
    const [countdownStarted, setCountdownStarted] = useState(false)

    useEffect(() => {
        if (!ad) return
        const delayTimer = setTimeout(() => {
            setCountdownStarted(true)
        }, delay * 1000)
        return () => clearTimeout(delayTimer)
    }, [ad])

    useEffect(() => {
        if (!ad || !countdownStarted) return
        if (countdownStarted && count > 0) {
            const timer = setTimeout(() => setCount(count - 1), 1000)
            return () => clearTimeout(timer)
        } else if (count === 0) {
            setIsDisabled(false)
        }
        return undefined
    }, [ad, countdownStarted, count])

    const onCloseCallback = useCallback(() => {
        if (typeof window === 'undefined') return
        window?.[CLEAR_CONVERGER_CALLBACK_KEY]?.()
    }, [])

    if (!ad) return null

    return (
        <>
            <ConvergerStyled
                theme={undefined}
                creative={creative}
                dimensions={dimensions}
                numOfTiles={numOfTiles}
                delay={delay}>
                <ConvergerCreative ad={ad} clickOptions={clickOptions} numOfTiles={numOfTiles} />

                <StyledCloseButton
                    onClick={isDisabled ? undefined : onCloseCallback}
                    disabled={isDisabled}
                    countdown={countdown}
                    delay={delay}>
                    <svg
                        className="svg-icon"
                        focusable="false"
                        viewBox="0 0 32 32"
                        color="text.default"
                        aria-hidden="true">
                        {count > 0 ? (
                            <text x="16" y="-9.25" textAnchor="middle" fontSize="20">
                                {count}
                            </text>
                        ) : (
                            <g transform="translate(16,16) scale(1.6) translate(-12,-12)">
                                <path d="M9 9L15 15M15 9L9 15" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
                            </g>
                        )}
                        <circle cx="16" cy="16" r="13" stroke="#fff" fill="rgba(255,255,255,0.8)" stroke-width="2" />
                    </svg>
                </StyledCloseButton>
            </ConvergerStyled>

            <Invisible>
                {/* Impression tracking via pixels */}
                <TrackingPixel src={clickOptions?.viewUrlUnesc} />
                <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} />
                {/* Impression tracking via html*/}
                {clickOptions?.thirdPartyTagsHtml && (
                    <InjectHtml
                        windowKey={INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME}
                        html={clickOptions?.thirdPartyTagsHtml}
                    />
                )}
            </Invisible>
        </>
    )
}

export default ConvergerNew
